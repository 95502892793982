import React from 'react';
import { Button, CookiePopup, DashboardPreview, Footer, Header, PhonePreview } from '../../components';
import { CheckCircleSolid } from '../../svgs';
import '../../styles/global.css';

export const LandingPage = () => {

    const viewDemo = () => {
        window.open('https://www.youtube.com/@dekkodo', '_blank');
    }

    const getStarted = () => {
        const email =  "info@dekkodo.com";
        const subject = "Sign up to Dekkodo!";
        const body = `
        Hi Dekkodo Team,

        I would like to start the sign-up process for the Dekkodo app. 
        Please provide me with the necessary information and steps to proceed.

        Thank you,
        `;
        const emailref = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
        window.open(emailref, '_blank');
    };
    
    return (
        <>
            <div className="fade">
                <div className="container">
                    <Header />
                    <div className='content'>
                        <h1>Workforce management made easy.</h1>
                        <p>Coordinate your sales rep activity and technicians with Dekkodo and bring a clear perspective to tasks with our unique features:</p>
                        <div className='feature'>
                            <CheckCircleSolid />
                            <p>Data acquisition</p>
                        </div>
                        <div className='feature'>
                            <CheckCircleSolid />
                            <p>Location tracking</p>
                        </div>
                        <div className='feature'>
                            <CheckCircleSolid />
                            <p>Movement frequency</p>
                        </div>
                        <div className='feature'>
                            <CheckCircleSolid />
                            <p>Audit monitoring</p>
                        </div>
                        <div className='content-buttons'>
                            <Button
                                onPress={getStarted}
                                text='get started'
                            />
                            <Button
                                onPress={viewDemo}
                                text='view demo'
                                color='secondary'
                            />
                        </div>
                    </div>
                    <div>
                        <PhonePreview />
                        <DashboardPreview />
                    </div>
                    <Footer />
                </div>
            </div>
            <CookiePopup />
        </>
    );
};