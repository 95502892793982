import { FC } from "react";
import { classnames } from "../../functions";
import { Link } from "react-router-dom";

interface IFooterProps {
    className?: string;
}

export const Footer: FC<IFooterProps> = ({
    className = ''
}) => {
    return (
        <div className={classnames('footer', "footer-text", className)}>
            <span>© 2024 Dekkodo</span>
            {/* TODO: Once we have the Terms & Conditions document we can uncomment */}
            {/* <Link to={"/terms-and-conditions"}>Terms + Conditions</Link> */}
            <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </div>
    );
};