import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '..';
import { classnames } from '../../functions';
import { DekkodoLogoSVG } from '../../svgs';

interface IHeaderProps {
    outlined?: boolean;
    className?: string;
}

export const Header: FC<IHeaderProps> = ({
    outlined = false,
    className = '',
}) => {
    const navigate = useNavigate();

    const onHome = () => {
        navigate("/");
    }

    const onSignIn = () => {
        window.open('https://dashboard.dekkodo.com', '_blank');
    }

    return (
        <div className={classnames(className, 'header')}>
            <DekkodoLogoSVG onClick={onHome} className={"hover-logo"} />
            <Button
                onPress={onSignIn}
                className='button-header'
                text='sign in'
                color='secondary'
                outlined={outlined}
            />
        </div>
    )
}