import React from 'react';

const PolicyAgreement = () => {
  return (
    <div>
      <h2>1. INTERPRETATION</h2>
      <p>1.1. In this Agreement, unless inconsistent with the context, the following words and expressions will bear the meanings assigned thereto and cognate words and expressions will bear corresponding meanings:</p>
      <ul className='custom-list'>
        <ul>1.1.1. "Agreement" means this agreement including the annexures and schedules hereto;</ul>
        <br></br>
        <ul>1.1.2. "Business day" means any day other than a Saturday, Sunday or public holiday;</ul>
        <br></br>
        <ul>1.1.3. “Data Subjects” means persons whose Personal Information may be processed during the course and scope of providing the Services, as defined in POPIA;</ul>
        <br></br>
        <ul>1.1.4. “Parties”  means the Responsible Party and the Service Provider, and the term “Party” will mean either of them, as the context may require;</ul>
        <br></br>
        <ul>1.1.5. “Personal Information” means personal information as contemplated and defined in POPIA;</ul>
        <br></br>
        <ul>1.1.6. “POPIA” means the Protection of Personal Information Act 4 of 2013;</ul>
        <br></br>
        <ul>1.1.7. “Processing”, in the context of Personal Information means such processing activities as the Parties may engage in in respect of such Personal Information, as defined in POPIA;</ul>
        <br></br>
        <ul>1.1.8. “the Services” means the services rendered by the Service Provider to the Responsible Party in terms of a separate agreement or the Srvice Provider’s standard terms and conditions of service; and</ul>
        <br></br>
        <ul>1.1.9. “the Signature Date” means the date on which this agreement is signed by the last party signing it.</ul>
        <br></br>
      </ul>

      <h2>2. INTRODUCTION</h2>
      <p>2.1. The Service Provider renders the Services to the Responsible Party, which may require that the Service Provider processes Personal Information on the Responsible Party’s behalf.</p>
      <p>2.2.Section 20 of POPIA provides that the Responsible Party must enter into a written agreement with the Service Provider in terms of which the Service Provider makes certain undertakings relating to security and notification requirements in respect of Personal Information processed on the Responsible Party’s behalf.</p>
      <p>2.3.The Parties accordingly wish to record the undertakings made by them in respect of any Personal Information that may be processed by the Service Provider on behalf of the Responsible Party in respect of the Services.</p>

      <h2>3. DURATION</h2>
      <p>3.1. This Agreement will come into effect on the Signature Date and will remain in effect until such time as the Services are terminated, or the Service Provider has returned, destroyed, or permanently de-identified any Personal Information that it may have processed on the Responsible Party’s behalf, whichever occurs last.</p>

      <h2>4. SECURITY AND NOTIFICATION UNDERTAKINGS</h2>
      <p>4.1. Insofar as any information processed by the Service Provider on behalf of the Responsible Party constitutes Personal Information, the Service Provider undertakes that it will:</p>
      <ul className='custom-list'>
        <ul>4.1.1. not use the Personal Information otherwise than as directed by the Responsible Party, or as provided for in the contract or terms of conditions relating to the Services;</ul>
        <br></br>
        <ul>4.1.2. not disclose Personal Information to any third party without the Responsible Party’s prior written permission;</ul>
        <br></br>
        <ul>4.1.3.implement and maintain reasonable technical and organisational measures to secure the integrity and confidentiality of any Personal Information processed by it on the Responsible Party’s behalf and to prevent loss of, damage to or unauthorised access, processing or destruction thereof.</ul>
        <br></br>
      </ul>
      <p>4.2. The Service Provider undertakes that, in the event that it reasonably suspects that the integrity or confidentiality of any Personal Information processed by it on behalf of the Responsible Party has been compromised, it will as soon as reasonably possible notify the Responsible Party and will provide its full and reasonable cooperation to the Responsible Party and relevant authorities to investigate and contain any such suspected breach.</p>

      <h2>5. WARRANTIES AND INDEMNITY BY THE RESPONSIBLE PARTY</h2>
      <p>5.1. The Responsible Party warrants in favour of the Service Provider that:</p>
      <ul className='custom-list'>
        <ul>5.1.1. it has obtained the required consent, or other lawful basis to process the Personal Information of any Data Subject whose Personal Information is processed by the Service Provider on the Responsible Party’s behalf;</ul>
        <br></br>
        <ul>5.1.2. it will employ the same security measures referred to in clause 4.1.3 in relation to any Personal Information processed by it in the context of the Services; and</ul>
        <br></br>
        <ul>5.1.3. it will be bound by the same notification obligations contemplated in clause 4.2 towards the Service Provider.</ul>
        <br></br>
      </ul>
      <p>5.2. The Responsible Party hereby indemnifies and holds the Service Provider harmless against any liability arising from any claim, fine or sanction by any Data Subject, affected person or regulatory authority, relating to any unlawful access to, or damage or destruction of, any Personal Information processed by the Service Provider on the Responsible Party’s behalf in respect of the Services, which occurred in spite of the Service Provider’s compliance with the provisions of clause 4.1.3.</p>

      <h2>6. BREACH</h2>
      <p>6.1. Save as otherwise provided in this Agreement, if any Party commits a breach of this Agreement and/or fails to comply with any of the provisions hereof ("the Defaulting Party"), then the other party ("the Aggrieved Party") will be entitled to give the Defaulting Party 14 (fourteen) days’ notice in writing to remedy such breach and/or failure, and if the Defaulting Party fails to comply with such notice then the Aggrieved Party will forthwith be entitled, but not obliged, without prejudice to any other rights or remedies which the Aggrieved Party may have in law, including the right to claim damages:</p>
      <ul className='custom-list'>
        <ul>6.1.1. to cancel this Agreement; or</ul>
        <br></br>
        <ul>6.1.2. to claim immediate performance and/or payment of all the Defaulting Party's obligations in terms hereof.</ul>
        <br></br>
      </ul>

      <h2>7. NOTICES</h2>
      <p>7.1. The Parties hereto choose domicilia citandi et executandi for all purposes under this Agreement including delivery of notices and service of court process at the following addresses:</p>
      <p>7.1.1. <strong>The Responsible Party</strong><br />
        7.1.2. <strong>Physical Address:</strong> ______________________________<br />
        7.1.3. <strong>Email:</strong> ______________________________</p>
      <p>7.1.4. <strong>The Service Provider</strong><br />
      <strong>Physical Address:</strong> ______________________________<br />
      <strong>Email:</strong> ______________________________</p>
      <p>7.2. Any notices to any Party will be addressed to its domicilium aforesaid and either sent by prepaid registered post, delivered by hand or electronic mail. In the case of:</p>
      <ul className='custom-list'>
        <ul>7.2.1. any notice sent by prepaid registered post, it will be deemed to have been received, unless the contrary is proved, on the 10th (Tenth) business day after posting;</ul>
        <br></br>
        <ul>7.2.2. any notice delivered by hand, it will be deemed to have been received, unless the contrary is proved, on the date of delivery, provided such date is a business day or otherwise on the next following business day;</ul>
        <br></br>
        <ul>7.2.3 any communication by electronic mail, it will be deemed to have been received, unless the contrary is proved, 1 (One) hour after the time of transmission providing transmission occurs within normal business hours and the day of transmission is a business day, or otherwise on the next following business day.</ul>
        <br></br>
      </ul>
      <p>7.3 Any Party will be entitled, by notice to the other to change its domicilium to such other street address within the Republic of South Africa, provided that the change will become effective only 14 (Fourteen) days after service of the notice in question.</p>

      <h2>8. GENERAL</h2>
      <p>8.1. No alteration, cancellation, variation of, or addition hereto will be of any force or effect unless reduced to writing and signed by all the parties to this Agreement or their duly authorised representatives.</p>
      <p>8.2. This document contains the entire Agreement between the Parties hereto in relation to these presents and no Party will be bound by any undertakings, representations, warranties, promises or the like not recorded herein.</p>
      <p>8.3. No indulgence which any Party ("the grantor") may grant to any other ("the grantee") will constitute a waiver of any of the rights of the grantor, who will not thereby be precluded from exercising any rights against the grantee which may have arisen in the past or which may arise in the future.</p>
      <p>8.4. This Agreement will be governed by and construed in accordance with the Laws of the Republic of South Africa.</p>
      <p>8.5. This Agreement may be signed in counterparts and, if so signed, all such counterparts taken together will be deemed to be and construed as one instrument. An electronic copy of a signed counterpart will be sufficient proof of signature of this Agreement.</p>
    </div>
  );
};

export default PolicyAgreement;
