import React from 'react';
import { Footer, Header } from '../../components';
import '../../styles/global.css';

export const TermsAndConditionsPage = () => {

    //TODO interface for terms
    const [terms, setTerms] = React.useState<any>({
        effective_date: '20/06/2024',
        last_updated: '20/06/2024',
        introduction: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
        paragraphs: [
            {
                title: '1. Title',
                paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                title: '2. Title',
                paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                title: '3. Title',
                paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            },
            {
                title: '4. Title',
                paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            }
        ]
    });

    //TODO add in api call here to get the latest ts & cs
    // React.useEffect(() => {
    //     //service.get
    //     //.then((res) => {
    //     setTerms()
    // }, [])

    return (
        <div className="container">
            <Header outlined={true} />
            <div className='content-terms'>
                <h1>Terms and Conditions</h1>
                <div className='terms-date'>
                    <p>effective date: {terms.effective_date}</p>
                    <p>last updated: {terms.last_updated}</p>
                </div>

                <div className='fade-terms'>
                    {terms?.introduction &&
                        <p>{terms.introduction}</p>
                    }
                    {terms?.paragraphs.map(({ title, paragraph }: any, i: number) => (
                        <div key={title + i}>
                            <h1>{title}</h1>
                            <p>{paragraph}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};