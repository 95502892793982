import { FC } from "react";
import { classnames } from "../../functions";

interface IButtonProps {
    text: string;
    color?: 'primary' | 'secondary';
    outlined?: boolean;
    className?: string;
    onPress?: () => void;
}

export const Button: FC<IButtonProps> = ({
    text = '',
    color = 'primary',
    outlined = false,
    className = '',
    onPress = () => { },
}) => {
    return (
        <button
            onClick={onPress}
            className={classnames(
                'button-base',
                `button-${color}`,
                outlined && 'button-outlined',
                className
            )}
        >
            {text}
        </button>
    );
}