import { FC } from "react"
import { classnames } from "../../functions"
import { ISvgProps } from "../../interfaces/base-svg"

export const CheckCircleSolid: FC<ISvgProps> = ({
    color = '#0B2355',
    size = 18,
    className,
}) => {
    return (
        <div className={classnames(className)}>
            <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill={color} />
                <path d="M7.97155 12.5298L4.5 9.05748L5.65691 7.90057L7.97155 10.2144L12.5992 5.58594L13.7569 6.74366L7.97155 12.5298Z" fill="white" />
            </svg>
        </div>
    )
}