import React, { FC, useEffect, useState } from "react";
import { Footer, Header } from "../../components";
import '../../styles/global.css';
import PolicyAgreement from "./PolicyAgreement";

export const PrivacyPolicyPage: FC = () => {

    return (
        <div className="container">
            <Header outlined={true} />
            <div className='content-terms'>
                <h1>Privacy Policy</h1>
                <div className='fade-terms' > 
                    <PolicyAgreement />
                </div>
            </div>
            <Footer />
        </div>
    )
}