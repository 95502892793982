import { FC, ReactElement } from "react"

interface IPhonePreviewProps {
    children?: ReactElement,
}

export const PhonePreview: FC<IPhonePreviewProps> = ({
    children
}) => {
    return (
        <div className="phone">
            {children}
        </div>
    )
}
