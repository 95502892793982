import { FC, ReactElement } from "react"

interface IDashboardPreviewProps {
    children?: ReactElement;
}

export const DashboardPreview: FC<IDashboardPreviewProps> = ({
    children,
}) => {
    return (
        <div className="dashboard">
            {children}
        </div>
    )
}