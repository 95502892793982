import { FC } from "react";
import { classnames } from "../../functions";
import { ISvgProps } from "../../interfaces/base-svg";

export const DekkodoLogoSVG: FC<ISvgProps> = ({
    className,
    onClick = () => { },
}) => {
    return (
        <div
            onClick={() => onClick()}
            className={classnames('logo', className)}
        >
            <svg width="142" height="28" viewBox="0 0 142 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 13.7415H7.50735C7.50735 10.2983 10.2983 7.50735 13.7415 7.50735C17.1848 7.50735 19.982 10.2983 19.982 13.7415H27.4893C27.4893 6.15264 21.3367 0 13.7415 0C6.15264 0 0 6.15264 0 13.7415Z" fill="#9FD8FA" />
                <path d="M27.4893 13.7417C27.4893 21.3306 21.3367 27.4832 13.7478 27.4832C6.15892 27.4832 0 21.3369 0 13.7417H7.50735C7.50735 17.1849 10.2983 19.9821 13.7478 19.9821C17.1973 19.9821 19.982 17.1912 19.982 13.7417H27.4893Z" fill="#0B2355" />
                <path d="M19.9819 13.7415H7.50732C7.50732 10.2983 10.2983 7.50732 13.7478 7.50732C17.1973 7.50732 19.9819 10.2983 19.9819 13.7415Z" fill="#538BC6" />
                <path d="M49.7794 21.7947H46.1919V20.5404H45.9912C45.0504 21.6442 43.6832 22.0582 42.0525 22.0582C38.7911 22.0582 36.1068 19.8881 36.1068 15.2344C36.1068 10.5807 38.7911 8.4107 42.0525 8.4107C43.6204 8.4107 44.9626 8.78701 45.9034 9.82813H46.0915V3.73193H49.7794V21.7947ZM43.0434 19.2108C44.9877 19.2108 46.2546 17.8184 46.2546 15.2344C46.2546 12.6504 44.9877 11.2456 43.0434 11.2456C41.0992 11.2456 39.8323 12.6379 39.8323 15.2344C39.8323 17.831 41.0992 19.2108 43.0434 19.2108Z" fill="black" />
                <path d="M58.9864 8.36035C63.0756 8.36035 65.5592 10.706 65.5592 14.7451V16.2377H55.5996C55.6498 18.0189 56.879 19.3736 58.9613 19.3736C61.0435 19.3736 61.8965 18.3827 62.0846 17.5799H65.2958V17.9562C64.8819 19.7625 63.1885 22.0956 59.024 22.0956C54.8595 22.0956 52.0748 19.8001 52.0748 15.2217C52.0748 10.6433 54.847 8.36035 58.9864 8.36035ZM62.0972 13.729C62.0721 12.2489 61.0435 11.0447 58.9362 11.0447C56.8289 11.0447 55.7877 12.2865 55.6372 13.729H62.0972Z" fill="black" />
                <path d="M71.6679 3.73193V13.4909H71.8686L77.0742 8.66158H81.3391V9.03788L74.8038 14.9083L81.3767 21.4184V21.7947H76.999L71.8686 16.539H71.6679V21.7947H67.9801V3.73193H71.6679Z" fill="black" />
                <path d="M86.827 3.73193V13.4909H87.0277L92.2333 8.66158H96.4981V9.03788L89.9629 14.9083L96.5357 21.4184V21.7947H92.158L87.0277 16.539H86.827V21.7947H83.1392V3.73193H86.827Z" fill="black" />
                <path d="M103.667 8.36035C107.794 8.36035 110.741 10.7311 110.741 15.2343C110.741 19.7374 107.794 22.0956 103.667 22.0956C99.5399 22.0956 96.5922 19.7374 96.5922 15.2343C96.5922 10.7311 99.5399 8.36035 103.667 8.36035ZM103.667 19.2733C105.736 19.2733 107.003 17.8182 107.003 15.2343C107.003 12.6503 105.736 11.1952 103.667 11.1952C101.597 11.1952 100.33 12.6377 100.33 15.2343C100.33 17.8308 101.61 19.2733 103.667 19.2733Z" fill="black" />
                <path d="M125.725 21.7947H122.137V20.5404H121.936C120.996 21.6442 119.628 22.0582 117.998 22.0582C114.736 22.0582 112.052 19.8881 112.052 15.2344C112.052 10.5807 114.736 8.4107 117.998 8.4107C119.566 8.4107 120.908 8.78701 121.849 9.82813H122.037V3.73193H125.725V21.7947ZM118.989 19.2108C120.933 19.2108 122.2 17.8184 122.2 15.2344C122.2 12.6504 120.933 11.2456 118.989 11.2456C117.044 11.2456 115.777 12.6379 115.777 15.2344C115.777 17.831 117.044 19.2108 118.989 19.2108Z" fill="black" />
                <path d="M134.925 8.36035C139.052 8.36035 142 10.7311 142 15.2343C142 19.7374 139.052 22.0956 134.925 22.0956C130.799 22.0956 127.851 19.7374 127.851 15.2343C127.851 10.7311 130.799 8.36035 134.925 8.36035ZM134.925 19.2733C136.995 19.2733 138.262 17.8182 138.262 15.2343C138.262 12.6503 136.995 11.1952 134.925 11.1952C132.856 11.1952 131.589 12.6377 131.589 15.2343C131.589 17.8308 132.868 19.2733 134.925 19.2733Z" fill="black" />
            </svg>
        </div>
    )
}