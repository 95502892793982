import React, { FC } from "react";
import { Button } from "../Button";
import { classnames } from "../../functions";

export const CookiePopup: FC = () => {

    const [show, setShow] = React.useState<boolean>(false);

    const onDismiss = () => {
        setShow(false);
    }

    React.useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 5000);
    }, [])

    return (
        <div className={classnames("sticky-footer", !show && 'hidden')}>
            <div className="sticky-content">
                <strong>This website uses cookies</strong>
                <p>We use cookies to personalise content and ads, to provide social media features and to analyse
                    our traffic. We also share information about your use of our site with our social media, advertising
                    and analytics partners who may combine it with other information that you've provided to them or
                    that they've collected from your use of their services. You consent to our cookies if you continue to
                    use our website.</p>
                <div className="sticky-buttons">
                    <Button
                        onPress={onDismiss}
                        text="Confirm"
                        color="primary"
                    />
                    <Button
                        onPress={onDismiss}
                        text="Deny"
                        outlined
                    />
                </div>
            </div>
        </div>
    );
};